import { useEffect, useState } from 'react';
import LayoutContainer from '../components/Layout/Container';
import ScrollContainer from '../components/ScrollContainer';
import useRequest from '../hooks/useRequest';
import { useNavigate } from 'react-router-dom';

const url = process.env.REACT_APP_BASE_BASE_URL;

export default function Home() {
  const { reqBase } = useRequest();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(9);
  const [noMoreData, setNoMoreData] = useState(false);

  const getData = async () => {
    const response = await reqBase('front/arboles?l=9&o=0');
    setData(response.data.datos);
  };

  const handleScroll = async (e: any) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;

    if (scrollHeight - scrollTop === clientHeight && !noMoreData) {
      try {
        const res = await reqBase.get(`front/arboles?l=9&o=${offset}`);

        if (res.data.datos.length > 0) {
          //@ts-ignore
          setData([...data, ...res.data.datos]);
          setOffset(offset + 9);
          return;
        }

        setNoMoreData(true);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getData();

    //eslint-disable-next-line
  }, []);

  const Card = ({ data }: any) => {
    return (
      <div className="col-12 col-sm-6 col-lg-4 p-3">
        <div
          onClick={() => navigate(`/vista/${data.arb_int_id}`)}
          className="card text-center card-home"
          style={{ cursor: 'pointer', height: '516px' }}
        >
          <div
            className="card-header d-flex justify-content-between align-items-center"
            style={{ backgroundColor: '#235335' }}
          >
            <div className="d-flex align-items-center justify-content-center">
              <img src="logito.svg" alt="Logo" width={70} />
            </div>
            <span className="text-white h5 m-0">
              {data.arb_text_nombre_comun}
            </span>
          </div>
          <div
            className="card-body"
            style={{ height: '334px', flex: '0 1 auto' }}
          >
            <img
              src={`${url}front/arboles/${data.arb_int_id}/descargar`}
              alt="Imagen del árbol"
              height="300"
              className="w-100"
              style={{ objectFit: 'scale-down' }}
            />
          </div>
          <div className="card-footer p-0 flex-grow-1 d-flex flex-column">
            <div className="row m-0 p-0 w-100 flex-grow-1 border-bottom">
              <div
                className="col-5 d-flex align-items-center justify-content-start"
                style={{ backgroundColor: '#235335' }}
              >
                <span className="text-white">N. Científico:</span>
              </div>
              <div className="col-7 d-flex align-items-center justify-content-center">
                <span className="fw-bold" style={{ color: '#235335' }}>
                  {data.arb_text_nombre_cientifico}
                </span>
              </div>
            </div>
            <div className="row m-0 p-0 w-100 flex-grow-1 border-bottom">
              <div
                className="col-5 d-flex align-items-center justify-content-start"
                style={{ backgroundColor: '#235335' }}
              >
                <span className="text-white">Familia:</span>
              </div>
              <div className="col-7 d-flex align-items-center justify-content-center">
                <span className="fw-bold" style={{ color: '#235335' }}>
                  {data.arb_text_familia}
                </span>
              </div>
            </div>
            <div className="row m-0 p-0 w-100 flex-grow-1">
              <div
                className="col-5 d-flex align-items-center justify-content-start"
                style={{ backgroundColor: '#235335' }}
              >
                <span className="text-white">Dirección:</span>
              </div>
              <div className="col-7 d-flex align-items-center justify-content-center">
                <span className="fw-bold" style={{ color: '#235335' }}>
                  {data.arb_text_direccion}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <LayoutContainer>
      <SearchModal />

      <button
        type="button"
        className="btn w-auto ms-auto mb-2 text-white"
        data-bs-toggle="modal"
        data-bs-target="#SearchModal"
        style={{ backgroundColor: '#9d2622', zIndex: '2' }}
      >
        Buscar <i className="fas fa-search ms-2"></i>
      </button>

      <div onScroll={handleScroll} className="scroll-container-home row mx-0">
        {data.map((item: any) => (
          <Card key={item.arb_int_id} data={item} />
        ))}
      </div>
    </LayoutContainer>
  );
}

const SearchModal = () => {
  return (
    <div
      className="modal fade"
      id="SearchModal"
      tabIndex={-1}
      data-bs-backdrop="false"
      aria-labelledby="SearchModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable modal-fullscreen">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="SearchModalLabel">
              Buscador
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <ScrollContainer
              type="arboles"
              url="front/arboles"
              headers={<ListaArbolesHeaders />}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cerrar <i className="fas fa-times ms-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ListaArbolesHeaders = () => {
  return (
    <div className="row bg-secondary w-100 mx-0 rounded mb-2">
      <span className="col-2 col-md-1 text-center border-end text-white py-2 text-truncate">
        Ficha
      </span>
      <span className="col-5 col-md-2 text-center border-end text-white py-2 text-truncate">
        Nombre común
      </span>

      <span className="d-none d-md-block col-md-2 text-center border-end text-white py-2 text-truncate">
        Nombre científico
      </span>
      <span className="d-none d-md-block col-md-1 text-center border-end text-white py-2 text-truncate">
        Familia
      </span>
      <span className="d-none d-md-block col-md-1 text-center border-end text-white py-2 text-truncate">
        Altura total
      </span>
      <span className="d-none d-md-block col-md-3 text-center border-end text-white py-2 text-truncate">
        Dirección
      </span>

      <span className="col-5 col-md-2 text-center text-white py-2 text-truncate">
        Opciones
      </span>
    </div>
  );
};
