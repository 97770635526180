export default function InputSelect({
  className,
  label,
  id,
  value,
  name,
  onChange,
  isInvalid,
  options,
  placeholder = "Selecciona una opción",
}: any) {
  return (
    <div className={className}>
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <select
        name={name}
        onChange={onChange}
        value={value}
        className={`form-select ${isInvalid ? "is-invalid" : ""}`}
        id={id}
        aria-describedby={`${id}Feedback`}
      >
        {!value && <option value="">{placeholder}</option>}
        {options?.map((item: any) => (
          <option key={item.value} value={item.value}>
            {item.name}
          </option>
        ))}
      </select>

      {isInvalid && (
        <div id={`${id}Feedback`} className="invalid-feedback">
          {isInvalid}
        </div>
      )}
    </div>
  );
}
