import axios from "axios";
import qs from "qs";

const BASE_URL = process.env.REACT_APP_ADMINISTRATION_BASE_URL;
const BASE_BASE_URL = process.env.REACT_APP_BASE_BASE_URL;

let userSecret = process.env.REACT_APP_OAUTH2_CLIENT;
let passSecret = process.env.REACT_APP_OAUTH2_SECRET;

const base64 = btoa(userSecret + ":" + passSecret);

export default function useRequest() {
  const reqLogin = axios.create({
    baseURL: BASE_BASE_URL,
    headers: {
      Authorization: `Basic ${base64}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    transformRequest: [(data) => qs.stringify(data)],
  });

  const reqBase = axios.create({
    baseURL: BASE_BASE_URL,
    headers: {
      Authorization: `Basic ${base64}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    transformRequest: [(data) => qs.stringify(data)],
  });

  const reqToken = axios.create({
    baseURL: BASE_URL,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    transformRequest: [(data) => qs.stringify(data)],
  });

  const reqTokenBase = axios.create({
    baseURL: BASE_BASE_URL,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    transformRequest: [(data) => qs.stringify(data)],
  });

  const reqTokenFormData = axios.create({
    baseURL: BASE_URL,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
      "Content-Type": "multipart/form-data",
    },
    // transformRequest: [(data) => qs.stringify(data)],
  });

  const reqTokenBaseFormData = axios.create({
    baseURL: BASE_BASE_URL,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
      "Content-Type": "multipart/form-data",
    },
    // transformRequest: [(data) => qs.stringify(data)],
  });

  const reqUpload = axios.create({
    baseURL: BASE_URL,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
      "Content-Type": "multipart/form-data",
    },
  });

  const reqDownload = axios.create({
    baseURL: BASE_BASE_URL,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
      "Content-Type": "multipart/form-data",
    },
  });

  const donwloadFile = (fileData: any, name: string) => {
    const href = window.URL.createObjectURL(new Blob([fileData]));
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", name); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  return {
    reqLogin,
    reqToken,
    reqTokenBase,
    reqTokenFormData,
    reqTokenBaseFormData,
    reqUpload,
    reqDownload,
    donwloadFile,
    reqBase,
  };
}
