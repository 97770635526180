import { useState, useEffect } from "react";

import useScrollContainer from "../../hooks/useScrollContainer";
import ListItemSelector from "./ListItemSelector";
import InputSelect from "../../inputs/InputSelect";
import InputText from "../../inputs/InputText";
import useRequest from "../../hooks/useRequest";

export default function ScrollContainer({
  type,
  url,
  deleteUrl,
  headers,
  extraButton,
}: any) {
  const { reqBase } = useRequest();
  const [word, setWord] = useState("");
  const [location, setLocation] = useState("");
  const [species, setSpecies] = useState("");

  const [filters, setFilters] = useState({
    locations: [],
    species: [],
  });

  const {
    loading,
    error,
    data,
    deleteItem,
    erreasing,
    loadingMore,
    noMoreData,
    handleScroll,
    handleFilters,
    handleReload,
  } = useScrollContainer({ type, url, deleteUrl });

  const getFilters = async () => {
    const resLocations = await reqBase.get(`${url}/x/ubicaciones`);
    const resSpecies = await reqBase.get(`${url}/x/especies`);

    setFilters({
      locations: resLocations.data.map((item: any) => ({
        name: item.arb_text_direccion,
        value: item.arb_text_direccion,
      })),
      species: resSpecies.data.map((item: any) => ({
        name: item.arb_text_nombre_comun,
        value: item.arb_text_nombre_comun,
      })),
    });
  };

  const handleWord = ({ target }: any) => {
    setWord(target.value);
  };

  const handleLocation = ({ target }: any) => {
    setLocation(target.value);
  };

  const handleSpecies = ({ target }: any) => {
    setSpecies(target.value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    handleFilters(word, location, species);
  };

  const localReload = () => {
    handleReload();
    setWord("");
    setLocation("");
    setSpecies("");
  };

  useEffect(() => {
    getFilters();

    //eslint-disable-next-line
  }, []);

  return (
    <section className="w-100 d-flex flex-column align-items-center justify-content-center">
      <form
        className="w-100 d-flex justify-content-center align-items-end mb-4 gap-2 flex-wrap"
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        {extraButton}

        <div className="d-flex gap-3">
          <InputText
            className=""
            label="Por texto"
            placeholder='Ej: "Eucalipto"'
            id="nombre"
            value={word}
            name="nombre"
            onChange={handleWord}
            isInvalid=""
            required={false}
          />

          <InputSelect
            className=""
            label="Ubicación"
            id="ubicacion"
            value={location}
            name="ubicacion"
            onChange={handleLocation}
            isInvalid=""
            options={filters.locations}
          />

          <InputSelect
            className=""
            label="Especie"
            id="especie"
            value={species}
            name="especie"
            onChange={handleSpecies}
            isInvalid=""
            options={filters.species}
          />
        </div>

        <button type="submit" className="btn btn-primary">
          Buscar <i className="fas fa-search ms-2"></i>
        </button>

        <button onClick={localReload} type="button" className="btn btn-danger">
          Limpiar / Recargar
        </button>
      </form>

      {headers}

      <div
        onScroll={handleScroll}
        className="items-list-container border rounded w-100"
      >
        {loading && (
          <div className="py-5 d-flex align-items-center justify-content-center w-100 border-bottom">
            <div className="spinner-border text-dark" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}

        {error && (
          <div className="py-5 d-flex align-items-center justify-content-center w-100 border-bottom">
            <div
              className="alert alert-danger w-75 mx-auto text-center mb-0"
              role="alert"
            >
              Un error ha ocurrido, intentalo más tarde !
            </div>
          </div>
        )}

        {!loading && !error && (
          <ListItemSelector
            type={type}
            data={data}
            deleteItem={deleteItem}
            erreasing={erreasing}
          />
        )}

        {noMoreData && (
          <p className="w-100 py-3 mb-0 text-center fw-bold">
            No hay mas datos
          </p>
        )}

        {!loading && !error && data.length === 0 && (
          <div className="alert alert-warning text-center w-100 py-2 mb-0">
            No hay datos
          </div>
        )}

        {loadingMore && (
          <div className="py-3 d-flex align-items-center justify-content-center w-100 border-bottom">
            <div className="spinner-border text-dark" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
