import { Link } from "react-router-dom";

export default function Navbar() {
  return (
    <nav
      className="navbar navbar-expand-lg navbar-light bg-light border-bottom"
      style={{ zIndex: "2" }}
    >
      <div className="container-fluid">
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center">
            <Link to="/" className="navbar-brand d-md-none">
              <img
                src="/navbarmobil.png"
                alt="Juntos construimos la gran ciudad"
              />
            </Link>
            <Link to="/" className="navbar-brand d-none d-md-block">
              <img
                src="/navbarweb.png"
                alt="Juntos construimos la gran ciudad"
              />
            </Link>
          </div>

          <h1
            className="d-none d-lg-block h2 mb-0"
            style={{ color: "#235335" }}
          >
            Árboles Patrimoniales Ambato
          </h1>
        </div>
      </div>
    </nav>
  );
}
