import Navbar from './Navbar';

export default function LayoutContainer({ children }: any) {
  return (
    <>
      <Navbar />

      <div className="shadow-bg shadow-bg-1"></div>
      <div className="shadow-bg shadow-bg-2"></div>
      <div className="shadow-bg shadow-bg-3"></div>

      <div className="shadow-bg shadow-bg-4"></div>
      <div className="shadow-bg shadow-bg-5"></div>
      <div className="shadow-bg shadow-bg-6"></div>

      <img
        className="d-none d-lg-block"
        src="/letrasbg.svg"
        alt="Letras background"
        width={230}
        style={{
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          left: '55px'
        }}
      />

      <div
        className="align-items-center d-none d-md-flex gap-2"
        style={{
          position: 'absolute',
          bottom: '230px',
          right: '-170px',
          transform: 'rotate(270deg)',
          zIndex: '2'
        }}
      >
        <a
          href="https://www.ateneasoluciones.com"
          target="_blank"
          style={{ color: '#235335', textDecoration: 'none' }}
        >
          www.ateneasoluciones.com
        </a>
        <a
          href="https://www.ateneasoluciones.com"
          target="_blank"
        >
        <img src="/fb.svg" width={25} alt="Atenea Soluciones SA" />
        </a>
        <p
          style={{ color: '#235335', textDecoration: 'none' }}
          className="mb-0"
        >
          Gestión Ambiental
        </p>
      </div>

      <section
        className="container fluid flex-grow-1 py-3 py-md-5 d-flex flex-column"
        style={{ zIndex: '1000' }}
      >
        {children}
      </section>
    </>
  );
}
