import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./pages/Home";

import "./App.css";
import View from "./pages/View";

function App() {
  return (
    <main
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        maxHeight: "100vh",
        background: "#EEE",
      }}
      className="bg-light"
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/vista/:id" element={<View />} />

          <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default App;
