import { useNavigate } from "react-router-dom";

export default function ArbolItem({ key, item, deleteItem, erreasing }: any) {
  const navigate = useNavigate();

  return (
    <div className="row w-100 mx-0 rounded border-bottom list-item-hover align-items-center">
      <span className="col-2 col-md-1 text-center border-end py-2 text-truncate">
        {item.arb_text_ficha}
      </span>
      <span className="col-5 col-md-2 text-center border-end py-2 text-truncate">
        {item.arb_text_nombre_comun}
      </span>

      <span className="d-none d-md-block col-md-2 text-center border-end py-2 text-truncate">
        {item.arb_text_nombre_cientifico}
      </span>
      <span className="d-none d-md-block col-md-1 text-center border-end py-2 text-truncate">
        {item.arb_text_familia}
      </span>
      <span className="d-none d-md-block col-md-1 text-center border-end py-2 text-truncate">
        {item.arb_float_alt_total}
      </span>
      <span className="d-none d-md-block col-md-3 text-center border-end py-2 text-truncate">
        {item.arb_text_direccion}
      </span>

      <div className="col-5 col-md-2 py-2 d-flex gap-2 align-items-center justify-content-center">
        <button
          title="Ver detalle"
          type="button"
          className="btn btn-outline-secondary btn-sm"
          data-bs-dismiss="modal"
          onClick={() => navigate(`/vista/${item.arb_int_id}`)}
        >
          <i className="far fa-eye"></i>
        </button>
      </div>
    </div>
  );
}
