import ArbolItem from "./ItemsTypes/ArbolItem";

export default function ListItemSelector({
  type,
  data,
  deleteItem,
  erreasing,
}: any) {
  if (type === "arboles") {
    return data.map((item: any) => (
      <ArbolItem
        key={item.arb_int_id}
        item={item}
        deleteItem={deleteItem}
        erreasing={erreasing}
      />
    ));
  }
}
